@import "src/styles/helpers";

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  opacity: 0;
  animation: show 0.5s linear 0.5s forwards;
  pointer-events: none;
  user-select: none;
}

$imageHeightDesktop: 360px;
$imageHeightTablet: 254px;
$imageHeightMobile: 110px;

.number {
  height: $imageHeightDesktop;

  @include media(tablet) {
    height: $imageHeightTablet;
  }

  @include media(mobile) {
    height: $imageHeightMobile;
  }

  img {
    height: 100%;
    width: auto;
  }
}

.zero {
  position: relative;
  top: -40px;

  img {
    transform: scale(1.1);
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.title {
  color: var(--primary-600);
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.2px;
  margin: 64px 0 44px;

  @include media(tablet) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.96px;
    margin: 40px 0 24px;
  }

  @include media(mobile) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.72px;
    margin: 32px 0 24px;
  }
}

.button {
  width: fit-content;
  margin: 0 auto;
}

.content {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 120px;
  width: 100%;
  min-height: calc(100vh - 136px);

  @include media(tablet) {
    min-height: calc(100vh - 112px);
  }

  @include media(mobile) {
    min-height: calc(100vh - 112px);
  }
}

.vector1 {
  position: absolute !important;
  top: 0;
  right: 400px;

  @include media(desktop) {
    right: 370px;
  }

  @include media(desktopSmall) {
    right: 370px;
  }

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}

.vector2 {
  position: absolute !important;
  top: 200px;
  right: 0;

  @include media(desktop) {
    right: 370px;
  }

  @include media(desktopSmall) {
    right: 0px;
  }

  @include media(tablet) {
    right: 0px;
    top: 50px;
  }

  @include media(mobile) {
    display: none;
  }
}

.vector3 {
  position: absolute !important;
  bottom: 50px;
  right: 1100px;

  @include media(desktop) {
    right: 370px;
  }

  @include media(desktopSmall) {
    left: 50px;
    right: unset;
  }

  @include media(tablet) {
    left: 50px;
    right: unset;
  }

  @include media(mobile) {
    left: 50px;
    right: unset;
  }
}

.vector4 {
  position: absolute !important;
  bottom: 300px;
  left: 200px;

  @include media(desktop) {
    right: 370px;
  }

  @include media(desktopSmall) {
    right: 200px;
    bottom: 150px;
    left: unset;
  }

  @include media(tablet) {
    right: 200px;
    bottom: 150px;
    left: unset;
  }

  @include media(mobile) {
    display: none;
  }
}